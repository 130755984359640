$fontstack: 'amiggoTXT', sans-serif;
$fontGG: 'amiggoTIT', sans-serif;
$fontbody: 'Open Sans', sans-serif;
$colordefault: #000;
$colorblue: #0457A3;
$colororange: #00A143;

@mixin transition($second) {
  -webkit-transition: all $second ease-in-out;
    -moz-transition: all $second ease-in-out;
        transition: all $second ease-in-out;
}